import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AlbumCard from "./AlbumCard"

import "../../styles/grid.css"

const GridAudioList = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAlbum(
        filter: { album_type: { eq: "audio" } }
        sort: { fields: release_date, order: DESC }
      ) {
        edges {
          node {
            title
            album_type
            release_date
            album_description
            front_cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `)

  const cards = data.allStrapiAlbum.edges.map(({ node }, index) => {
    const slug = `/audio/${node.title.replace(/\s/g, "_").replace(/\W/g, "")}`

    return (
      <AlbumCard
        key={index}
        cardImage={node.front_cover.localFile}
        cardTitle={node.title}
        cardDate={node.release_date}
        cardExcerpt={node.album_description}
        slug={slug}
      />
    )
  })

  return <div className="grid-container">{cards}</div>
}

export default GridAudioList
