import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import ReactMarkdown from "react-markdown"

import "../../styles/album-card.css"

const AlbumCard = props => {
  const { slug, cardImage, cardTitle, cardDate, cardExcerpt } = props

  return (
    <Link className="album-card" to={slug}>
      <div className="album-card-image">
        <GatsbyImage
          imgClassName="album-card-actual-image"
          image={getImage(cardImage)}
          alt=""
          loading="lazy"
          placeholder="dominantColor"
          objectFit="fill"
        />
      </div>
      <div className="album-card-main">
        <h2 className="album-card-title">{cardTitle}</h2>
        <div className="album-card-date">
          <Moment local format="YYYY">
            {cardDate}
          </Moment>
        </div>
      </div>
      <div className="album-card-body">
        <ReactMarkdown children={cardExcerpt} />
      </div>
    </Link>
  )
}

export default AlbumCard
